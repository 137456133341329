$(document).ready(function() {
    $('tr[data-href]').on("click", function() {
        document.location = $(this).data('href');
    });
    $('.container img').each(function() {  
   		$(this).addClass('img-responsive');
  	});  
  	$('.col-dreta img').each(function() {  
  		console.log(this);
  		$(this).addClass('pull-right');
  	});  
  	$('.col-esq img').each(function() {  
  		$(this).addClass('pull-left');
  	});
  	
    $(".selectpicker").selectpicker();
    $(".bootstrap-select").click(function () {
        $(this).addClass("open");
    });
});